import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {
    MONGODB_URI: z.string().url(),
    CAMPAIGNDB_MONGO_URI: z.string(),
    NODE_ENV: z.string(),
    DB_NAME: z.string(),
    CAMPAIGN_CONFIG_DB: z.string(),
    CAMPAIGN_CONFIG_DB_CAMPAIGNS_COLLECTION: z.string(),
    CAMPAIGN_CONFIG_DB_CAMPAIGN_RECORDS_COLLECTION: z.string(),
    CAMPAIGN_CONFIG_DB_CAMPAIGN_PROCESSED_RECORDS_COLLECTION: z.string(),
    AUTH_SECRET: z.string().min(1),
    HIGHLIGHT_SESSION_KEY: z.string(),
    DUENDE_IDS6_ID: z.string(),
    DUENDE_IDS6_SECRET: z.string(),
    DUENDE_IDS6_ISSUER: z.string().url(),
    CONFIG_SERVER_BASE_URL: z.string(),
    CAMPAIGN_CONFIG_SERVER_BASE_URL: z.string(),
    VERCEL_PROJECT_PRODUCTION_URL: z.string().optional(),
    AZURE_BLOB_STORAGE_ACCOUNT_NAME: z.string(),
    FLAGS_SECRET: z.string(),
    AZURE_BLOB_STORAGE_ACCOUNT_KEY: z.string(),
    AZURE_BLOB_STORAGE_CONTAINER_NAME: z.string().min(1),
    CLERK_SECRET_KEY: z.string().min(1),
    CLERK_INVITATION_REDIRECT_URI: z.string(),
  },
  client: {
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: z.string(),
  },
  // If you're using Next.js < 13.4.4, you'll need to specify the runtimeEnv manually
  runtimeEnv: {
    CONFIG_SERVER_BASE_URL: process.env.CONFIG_SERVER_BASE_URL,
    DB_NAME: process.env.DB_NAME,
    CAMPAIGN_CONFIG_DB: process.env.CAMPAIGN_CONFIG_DB,
    CAMPAIGN_CONFIG_DB_CAMPAIGNS_COLLECTION:
      process.env.CAMPAIGN_CONFIG_DB_CAMPAIGNS_COLLECTION,
    CAMPAIGN_CONFIG_DB_CAMPAIGN_RECORDS_COLLECTION:
      process.env.CAMPAIGN_CONFIG_DB_CAMPAIGN_RECORDS_COLLECTION,
    CAMPAIGN_CONFIG_DB_CAMPAIGN_PROCESSED_RECORDS_COLLECTION:
      process.env.CAMPAIGN_CONFIG_DB_CAMPAIGN_PROCESSED_RECORDS_COLLECTION,
    NODE_ENV: process.env.NODE_ENV,
    MONGODB_URI: process.env.MONGODB_URI,
    CLERK_INVITATION_REDIRECT_URI: process.env.CLERK_INVITATION_REDIRECT_URI,
    CAMPAIGN_CONFIG_SERVER_BASE_URL:
      process.env.CAMPAIGN_CONFIG_SERVER_BASE_URL,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL:
      process.env.NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL,
    CAMPAIGNDB_MONGO_URI: process.env.CAMPAIGNDB_MONGO_URI,
    AUTH_SECRET: process.env.AUTH_SECRET,
    VERCEL_PROJECT_PRODUCTION_URL: process.env.VERCEL_PROJECT_PRODUCTION_URL,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    HIGHLIGHT_SESSION_KEY: process.env.HIGHLIGHT_SESSION_KEY,
    DUENDE_IDS6_ID: process.env.DUENDE_IDS6_ID,
    DUENDE_IDS6_SECRET: process.env.DUENDE_IDS6_SECRET,
    DUENDE_IDS6_ISSUER: process.env.DUENDE_IDS6_ISSUER,
    AZURE_BLOB_STORAGE_ACCOUNT_NAME:
      process.env.AZURE_BLOB_STORAGE_ACCOUNT_NAME,
    FLAGS_SECRET: process.env.FLAGS_SECRET,
    AZURE_BLOB_STORAGE_ACCOUNT_KEY: process.env.AZURE_BLOB_STORAGE_ACCOUNT_KEY,
    AZURE_BLOB_STORAGE_CONTAINER_NAME:
      process.env.AZURE_BLOB_STORAGE_CONTAINER_NAME,
  },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  // experimental__runtimeEnv: {
  //   NEXT_PUBLIC_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_PUBLISHABLE_KEY,
  // }
});
